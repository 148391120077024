<template>
  <VanOverlay v-model:show="show" flex="~ col items-center justify-center" z-index="100000">
    <div w="80vw md:200" bg="#fff" text="center" rounded="4" m="auto" pt="6">
      <div text="#121212 sm md:lg" font="300 md:500">
        {{ t('key.jump.to.partner.page') }}
      </div>
      <img mt="13 md:30" src="~/assets/icons/azgoxplore-green.png" h="8 md:11" />

      <div my="5 md:8" h="14 md:19" flex="~ col items-center" space-y="3">
        <NuxtIcon name="my-icon:arrow-down" text="xl" class="color-animation-1" />
        <NuxtIcon name="my-icon:arrow-down" text="xl" class="color-animation-2" />
        <NuxtIcon name="my-icon:arrow-down" text="xl" class="color-animation-3" />
      </div>

      <img mb="25 md:40" :src="brandUrl" h="8 md:11" />
    </div>
  </VanOverlay>
</template>

<script lang="ts" setup>
defineProps<{
  brandUrl: string
}>()

const show = defineModel('show', {
  type: Boolean,
  default: false,
})

const { t } = useI18n()
</script>

<style>
@keyframes colorChange {
  0%, 100% {
    color: var(--el-color-primary-light-9);
  }
  33% {
    color: var(--el-color-primary);
  }
  66% {
    color: var(--el-color-primary-light-9);
  }
}

.color-animation-1 {
  animation: colorChange 1s infinite;
}

.color-animation-2 {
  animation: colorChange 1s infinite 0.2s;
}

.color-animation-3 {
  animation: colorChange 1s infinite 0.3s;
}
</style>
