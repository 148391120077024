<template>
  <div id="scroll-container" ref="scrollContainer" h="full" w="full" class="absolute" overflow-y="auto" flex="~ col">
    <!-- 顶部导航栏 -->
    <template v-if="!hideNavBar">
      <MobileNavBar v-if="isMobile" :show-back="true" />
      <div v-else w="full" bg="white" b-b="1 solid #00000014" sticky top-0 z="2000">
        <DesktopNavBar></DesktopNavBar>
      </div>
    </template>

    <slot></slot>
    <!-- 底部介绍 -->
    <MobileFooter v-if="isMobile" />
    <DesktopFooter v-else />
    <BusinessBJumpExternalPopup v-model:show="jumpLoading" :brand-url="jumpBrandUrl" />
  </div>
</template>

<script lang="ts" setup>
import Intercom from '@intercom/messenger-js-sdk'

const { scrollContainer } = useScrollContainer()
const { jumpLoading, jumpBrandUrl } = useSearchResult()

// 切换页面时滚动到顶部
// const scrollPageRef = useTemplateRef('scroll-page-ref')
// const nuxtApp = useNuxtApp()
// nuxtApp.hook('page:finish', () => {
//   scrollPageRef.value?.scrollTo(0, 0)
// })

await useInitializeApp()

useGlobalCssVars()

const route = useRoute()
// const { back } = useRouter()
// const title = useTitle('', { observe: true })

const hideNavBar = computed(() => Boolean(route.meta.hideNavBar))
const isMobile = useMobileByScreen()
// const isShowAppBar = computed(() => isMobile.value && route.query.campaign_id)

onMounted(() => {
  Intercom({
    app_id: 'iy1e1izd',
    vertical_padding: 60,
    horizontal_padding: 40,
    language_override: 'en',
  })
})
</script>

<style>
.intercom-app {
  position: absolute!important;
}

@media screen and (max-width: 450px) {
  .intercom-launcher {
    bottom: 60px !important;
  }
  div[class^="intercom-with-namespace-"] {
    bottom: 60px !important;
  }
}

@media screen and (max-width: 768px) {
  .intercom-lightweight-app, .intercom-app {
    z-index: 999 !important;
  }
}
</style>
